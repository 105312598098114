import ListController from "./list_controller"

export default class extends ListController {
  static targets = [ "section", "list" ]

  markItemDone(event) {
    const category = event.target.dataset.category;

    event.target.remove();

    this.listTargetForCategory('done').appendChild(event.target);
    this.sectionTargetForCategory('done').classList.remove('hidden');

    if (this.listTargetForCategory(category).children.length === 1) {
      this.sectionTargetForCategory(category).classList.add('hidden');
    }
  }

  markItemTodo(event) {
    const category = event.target.dataset.category;

    event.target.remove();

    this.listTargetForCategory(category).prepend(event.target);
    this.sectionTargetForCategory(category).classList.remove('hidden');

    if (this.listTargetForCategory('done').children.length === 1) {
      this.sectionTargetForCategory('done').classList.add('hidden');
    }
  }

  addListItem(event) {
    const div = document.createElement('div');
    div.innerHTML = event.detail;

    const category = div.firstChild.dataset.category;

    this.listTargetForCategory(category).appendChild(div.firstChild);
    this.sectionTargetForCategory(category).classList.remove('hidden');
  }

  listTargetForCategory(category) {
    return this.listTargets.find(listTarget => listTarget.dataset.category == category);
  }

  sectionTargetForCategory(category) {
    return this.sectionTargets.find(sectionTarget => sectionTarget.dataset.category == category);
  }
}
