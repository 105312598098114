import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "results" ]

  search() {
    const options = {
      body: JSON.stringify({ query: this.fieldTarget.value }),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(this.data.get('url'), options).then((response) => {
      return response.text();
    }).then((data) => {
      this.resultsTarget.innerHTML = data;
      this.showResults();
    }).catch((response) => {
      console.log(response);
    });
  }

  select(event) {
    this.fieldTarget.value = event.target.dataset.value;
    this.hideResults();
  }

  hideResults() {
    if (event.target != this.fieldTarget) {
      this.resultsTarget.classList.add('hidden');
    }
  }

  showResults(event) {
    if (this.resultsTarget.children.length > 0) {
      this.resultsTarget.classList.remove('hidden');
    }
  }
}
