import ListItemController from "./list_item_controller"

export default class extends ListItemController {
  static targets = [ "checkedIcon", "uncheckedIcon" ]

  checkItem(event) {
    this.checkedIconTarget.classList.remove('hidden');
    this.uncheckedIconTarget.classList.add('hidden');
  }

  uncheckItem(event) {
    this.checkedIconTarget.classList.add('hidden');
    this.uncheckedIconTarget.classList.remove('hidden');
  }
}
