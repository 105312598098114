import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "link", "body", "error" ]

  success(event) {
    let [data, status, xhr] = event.detail;
    this.formTarget.dispatchEvent(new CustomEvent('form:success', { bubbles: true, detail: xhr.response }));
    this.hide();
  }

  error(event) {
    let [data, status, xhr] = event.detail;

    this.formTarget.insertAdjacentHTML('afterend', xhr.response);
    this.formTarget.parentNode.removeChild(this.formTarget);
  }

  cancel() {
    this.hide();
  }

  hide() {
    this.formTarget.reset();

    if (this.hasBodyTarget) {
      this.bodyTarget.classList.add('hidden');
    }

    if (this.hasLinkTarget) {
      this.linkTarget.classList.remove('hidden');
    }

    this.errorTargets.forEach(function(errorTarget) {
      errorTarget.classList.remove('form-error');
    });
  }

  show() {
    this.linkTarget.classList.add('hidden');
    this.bodyTarget.classList.remove('hidden');
    this.bodyTarget.querySelector('input').focus();
  }
}
