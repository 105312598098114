import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  error(event) {
    let [data, status, xhr] = event.detail;
    console.error(xhr.response);
  }

  destroyed(event) {
    this.itemTarget.remove();
  }

  updated(event) {
    let [data, status, xhr] = event.detail;
    this.itemTarget.insertAdjacentHTML('afterend', xhr.response);
    this.itemTarget.nextSibling.dispatchEvent(new CustomEvent('list-item:updated', { bubbles: true }));
    this.itemTarget.parentNode.removeChild(this.itemTarget);
  }
}
