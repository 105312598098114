/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";
import "controllers";
import "bootstrap";
import "datalist-polyfill";
import "stylesheets/application.scss";
